import differenceInDays from 'date-fns/differenceInDays'
import en from '@/locales/en.json'
import { formatDateToJpFormat } from './dates'

export default function processBooking(booking) {
  const dateFields = [
    'checkinDate',
    'checkoutDate',
    'checkInDate',
    'checkOutDate',
    'checkedInAt',
    'checkedOutAt',
    'stayStartsAt',
    'stayEndsAt',
    'earlyCheckinAt',
    'lateCheckoutAt',
    'bookedAt',
  ]
  dateFields.forEach(field => {
    booking[field] = booking[field] && new Date(booking[field])
  })

  booking.earlyCheckinAt = booking.earlyCheckinAt?.toLocaleTimeString('en-US', {
    timeStyle: 'short',
  })

  booking.lateCheckoutAt = booking.lateCheckoutAt?.toLocaleTimeString('en-US', {
    timeStyle: 'short',
  })

  booking.numNights = differenceInDays(
    booking.checkoutDate || booking.checkOutDate,
    booking.checkinDate || booking.checkInDate
  )

  const bookingStatuses = en.general.statuses.booking

  booking.preCheckedIn = booking.tags?.includes('pre_checked_in')

  // RG has special status rules, Ops don't want to see SLRG bookings as
  // "in stay" as soon as they've been approved, since they get approved
  // in advance of arrival and it's confusing that a booking can show as "in stay"
  // before they arrive.

  const isOnlineCheckin = ['SLRG'].includes(booking.propertyAcronym)
  const isBookingPastStartTime = booking.stayStartsAt
    ? Date.parse(booking.stayStartsAt) < Date.now()
    : Date.parse(booking.checkinDate) < Date.now()

  const getStatusText = () => {
    if (isOnlineCheckin) {
      if (booking.status === 'cancelled') return bookingStatuses['cancelled']
      if (!isBookingPastStartTime) return bookingStatuses['arrival']
    }

    return bookingStatuses[booking.status]
  }

  booking.statusText = getStatusText()

  const checkinStatuses = en.general.statuses.checkin
  booking.checkins = booking.checkins?.map(ci => ({
    ...ci,
    statusText: checkinStatuses[ci.status],
    completedAt: ci.completedAt && new Date(ci.completedAt),
  }))

  return booking
}
