import { sortBy } from 'lodash'
import ChatsRepository from '@/api/repositories/chats.js'
import MessagesRepository from '@/api/repositories/messages.js'
import processBooking from '@/utils/process-booking'

export const state = {
  // list of chat rooms with just their room name and ID
  chats: [],
  // messages are in this format { 1 (chatId): [id: 1, content: "Go home and drink, grandpa.", profileId: 5] }
  messages: {},
  // this is a dedicated profiles for chat module. Slightly different from the profiles module
  // format is the same as the profiles module -> { <profile_id>: { <profile_information> } }
  profiles: {},
}

export const getters = {
  chats(state) {
    // Add name to chats
    const extraChatProperties = chat => {
      let name
      if (chat.profile) {
        name = chat.profile.fullName.trim()
      } else if (chat.announcement) {
        name = `${chat.property.name.replace('Section L', '')} guests`.trim()
      } else if (chat.property) {
        name = `${chat.property.acronym} Guests Chat`.trim()
      }

      let booking
      if (chat.booking) {
        if (!chat.booking.propertyAcronym) {
          booking = processBooking({
            ...chat.booking,
            ...{ propertyAcronym: chat.profile?.property?.acronym },
          })
        } else {
          booking = processBooking({ ...chat.booking })
        }
      }

      return { name, booking }
    }
    const chats = [...state.chats].map(chat => {
      return { ...chat, ...extraChatProperties(chat) }
    })
    return sortBy(chats, [
      chat => {
        const lastMessageAt = Math.max.apply(
          null,
          state.messages[chat.id]?.map(message => new Date(message?.createdAt))
        )
        return -lastMessageAt
      },
    ])
  },

  messages(state, getters, rootState, rootGetters) {
    const currentProfileId = rootGetters['auth/currentProfile'].id
    return Object.keys(state.messages).reduce((messagesObj, chatId) => {
      if (!state.messages[chatId]) return messagesObj
      // Fill each message with additional information to render them
      messagesObj[chatId] = state.messages[chatId].map(message => {
        return {
          ...message,
          mine: message.profileId === currentProfileId,
        }
      })
      return messagesObj
    }, {})
  },

  profiles(state) {
    return state.profiles
  },

  newMessagesCount(state) {
    return state.chats.reduce((sum, chat) => {
      sum += chat.notificationsCount
      return sum
    }, 0)
  },
}

export const mutations = {
  SET_CHATS(state, chats) {
    state.chats = chats
  },

  UPDATE_CHAT(state, { chat }) {
    const idx = state.chats.findIndex(c => c.id === chat.id)
    idx >= 0 ? (state.chats[idx] = chat) : state.chats.push(chat)
  },

  UPDATE_LAST_MESSAGE(state, { message }) {
    const idx = state.chats.findIndex(c => c.id === message.chatId)
    if (
      // replace if chat is found, and
      // there are no messages in the chat, or the lastMessage is older than the new message
      idx >= 0 &&
      (!state.chats[idx].lastMessage ||
        new Date(state.chats[idx].lastMessage?.createdAt) <
        new Date(message.createdAt))
    ) {
      state.chats[idx].lastMessage = message
    }
  },

  SET_MESSAGES(state, { messages, chatId }) {
    state.messages[chatId] = messages
  },

  ADD_MESSAGE(state, { message }) {
    if (state.messages[message.chatId]) {
      const idx = state.messages[message.chatId].findIndex(
        m => m.id === message.id
      )
      idx >= 0
        ? (state.messages[message.chatId][idx] = message)
        : state.messages[message.chatId].push(message)
    } else {
      state.messages[message.chatId] = [message]
    }
  },

  UPDATE_PROFILES(state, { profiles }) {
    // map the object in profileId => profile format
    const profilesObject = {}
    if (!profiles) return
    profiles.forEach(profile => (profilesObject[profile.id] = profile))
    state.profiles = { ...state.profiles, ...profilesObject }
  },

  SET_PROFILES(state, { profiles }) {
    // map the object in profileId => profile format
    const profilesObject = {}
    profiles.forEach(profile => (profilesObject[profile.id] = profile))
    state.profiles = profilesObject
  },

  ADD_PROFILE(state, { profile }) {
    state.profiles[profile.id] = profile
  },
}

export const actions = {
  async fetchChats({ commit }, { search = null, propertyId = null } = {}) {
    const res = await ChatsRepository.getChats({ search, propertyId })
    const chats = res.data.chats
    commit('SET_CHATS', chats)

    chats.forEach(chat => {
      if (chat.lastMessage) {
        commit('ADD_MESSAGE', { message: chat.lastMessage })
      }
    })

    return chats
  },

  async fetchChat(_, { chatId = null }) {
    if (!chatId) return
    const chat = await ChatsRepository.getChat({ chatId })
    return chat.data
  },

  async fetchMessages({ commit }, { currentChat, clearProfiles = false } = {}) {
    const { messages, profiles, ...chat } = currentChat

    commit('UPDATE_CHAT', { chat })
    commit(clearProfiles ? 'SET_PROFILES' : 'UPDATE_PROFILES', { profiles })
    commit('SET_MESSAGES', { messages, chatId: currentChat.id })

    return { messages, profiles }
  },

  addMessage({ commit }, { message = {}, profile = null } = {}) {
    if (profile) commit('ADD_PROFILE', { profile })
    commit('ADD_MESSAGE', { message })
    commit('UPDATE_LAST_MESSAGE', { message })
  },

  // === ActionCable === //
  async onReceived({ dispatch }, data) {
    if (data.error) {
      throw data
    } else {
      const { message, profile } = data
      await dispatch('addMessage', { message, profile })
    }
  },

  async sendMessage(_, { content }) {
    await this.$cable.perform({
      channel: 'ChatChannel',
      action: 'send_message',
      data: { content },
    })
  },

  async markAsRead(_, { messageId, chatId }) {
    await this.$cable.perform({
      channel: 'ChatChannel',
      action: 'mark_as_read',
      data: { messageId, chatId },
    })
  },

  async deleteMessage({ dispatch }, { message }) {
    const res = await MessagesRepository.deleteMessage(message.id)
    if (res.status === 200) {
      await dispatch('fetchMessages', { chatId: message.chatId })
    }
  },
}
