<template>
  <div class="flex flex-col min-h-screen pt-20 pb-48 bg-gray-100">
    <app-header class="fixed top-0 z-10" />
    <div
      class="flex flex-col flex-grow mx-auto"
      :class="[fullWidth ? 'w-full' : 'w-full p-16 pt-8']"
    >
      <router-view />
      <transition>
        <base-spinner
          v-if="appLoading"
          class="translate-center scale-[25%] fixed"
        />
      </transition>
    </div>
  </div>
  <alert-modal class="scale-90" />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AlertModal from '@/components/AlertModal.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
  components: { AppHeader, AlertModal },

  setup() {
    const store = useStore()
    const route = useRoute()

    const setViewHeight = () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    onMounted(() => {
      store.dispatch('setDOMLoaded')
      // for mobile vh
      setViewHeight()
      window.addEventListener('resize', setViewHeight)
    })

    return {
      fullWidth: computed(() => route.meta.fullWidth),
      appLoading: computed(() => store.getters['appLoading']),
    }
  },
}
</script>

<style lang="postcss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import 'nprogress/nprogress.css';
@import './assets/tailwind.css';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.translate-center {
  @apply left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.v-enter-active,
.v-leave-active {
  @apply transition-opacity duration-300;
}

.v-enter,
.v-leave-to {
  @apply opacity-0;
}

#nprogress .bar {
  background: blue;
}
</style>
