import algoliasearch from 'algoliasearch/lite'
import { config } from '@/constants'

const algoliaClient = algoliasearch(config.algolia.appId, config.algolia.key)

const bookingsIndex = algoliaClient.initIndex(config.algolia.bookingsIndex)
const bookingsIndexCheckoutDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckoutDesc
)
const bookingsIndexCheckinDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckinDesc
)
const bookingsIndexCheckoutAsc = algoliaClient.initIndex(
  config.algolia.bookingsIndexCheckoutAsc
)
const bookingsIndexRoomNumberDesc = algoliaClient.initIndex(
  config.algolia.bookingsIndexRoomNumberDesc
)

const indices = {
  bookings: bookingsIndex,
  today: bookingsIndex,
  tomorrow: bookingsIndex,
  future: bookingsIndex,
  past: bookingsIndexCheckoutDesc,
  checkInDesc: bookingsIndexCheckinDesc,
  checkOutAsc: bookingsIndexCheckoutAsc,
  roomNumberDesc: bookingsIndexRoomNumberDesc,
}

const getIndex = (scope, sortSelection = 'checkInDesc', searchQuery) => {
  if (searchQuery) return bookingsIndexCheckoutDesc // when there is a typed search query, the results are sorted by latest check out date first

  if (scope === 'instay') return indices[sortSelection] // when looking at the instay tab, the result sorting depends on the selected sorting option

  return indices[scope] || bookingsIndex // for all other scopes choose the relevant index, or if not known, the default 'bookings' index
}

export const fastSearch = async (
  scope,
  sortSelection,
  searchQuery,
  options
) => {
  const indexToSearch = getIndex(scope, sortSelection, searchQuery)
  if (!indexToSearch) return []

  return await indexToSearch.search(searchQuery, options)
}
