import BookingsRepository from '@/api/repositories/bookings.js'
import CheckinsRepository from '@/api/repositories/checkins.js'
import MinorsRepository from '@/api/repositories/minors.js'
import processBooking from '@/utils/process-booking'
import { fastSearch } from '@/use/use-algoliasearch'
import startOfTomorrow from 'date-fns/startOfTomorrow'
import en from '@/locales/en.json'
import { formatDateToJpFormat } from '@/utils/dates'

export const state = {
  booking: null,
}

export const getters = {
  booking: state => state.booking,
}

export const mutations = {
  SET_BOOKING(state, booking) {
    state.booking = booking
  },
  SET_BOOKING_CHECKIN(state, checkin) {
    state.booking.checkins.forEach((item, index) => {
      if (item.id === checkin.id) {
        // to avoid losing signature
        state.booking.checkins[index] = {
          ...state.booking.checkins[index],
          ...checkin,
        }
      }
    })
  },
}

export const actions = {
  async fastFetchBookings(
    { rootGetters },
    { searchText, facetFilters, filters, scope, page, sortSelection }
  ) {
    const propertyName = rootGetters['properties/property']?.name

    const propertyFilter = propertyName
      ? [`property:${propertyName}`]
      : ['property:-Test Property']

    const hitsPerPageByScope = {
      default: 20,
      today: 300,
      tomorrow: 300,
      instay: 20,
      past: 20,
      future: 20,
    }[scope || 'default']

    const searchOptions = {
      facetFilters: [...propertyFilter, ...(facetFilters || [])],
      filters: `${filters}`,
      hitsPerPage: hitsPerPageByScope,
      ...(page ? { page: page - 1 } : {}),
    }

    const results = await fastSearch(
      scope,
      sortSelection,
      searchText || '',
      searchOptions
    )

    let bookings = results.hits?.map(processBooking)
    if (scope === 'instay')
      bookings = bookings.filter(b =>
        ['In-stay', 'Due-out'].includes(b.statusText)
      ) // remove bookings from online-checkin properties that are online checked in but not actually in-stay

    return {
      bookings: bookings,
      paginationMeta: {
        recordsPerPage: hitsPerPageByScope,
        numRecords: results.hits.length,
        totalPages: results.nbPages,
        totalRecords: results.nbHits,
      },
    }
  },

  async fetchBookings({ dispatch }, params) {
    const { previousRequest, cancelToken } = await dispatch(
      'getCancelTokens',
      'bookings/fetchBookings',
      { root: true }
    )
    previousRequest?.cancel('Request canceled by new request')

    const res = await BookingsRepository.getBookings({ ...params, cancelToken })

    if (res.status === 200) {
      res.data.records = res.data.records.map(b => processBooking(b))

      // Cache bookings by scope/subscope
      const { scope, subscope } = params
      dispatch(
        'tables/cacheData',
        { module: 'bookings', scope: subscope || scope, data: res.data },
        { root: true }
      )

      return res.data
    }
  },

  async fetchBooking(_, { bookingNo }) {
    const response = await BookingsRepository.getBooking({ bookingNo })
    if (response.status === 200) return processBooking(response.data)
  },

  async updateBooking(_, { booking }) {
    const response = await BookingsRepository.patchBooking({ booking })
    if (response.status === 200) return processBooking(response.data)
  },

  async updateBookingRoomCode(_, { bookingNo, roomCode }) {
    const response = await BookingsRepository.patchBookingRoomCode({
      bookingNo,
      roomCode,
    })
    if (response.status === 200) return processBooking(response.data)
  },

  async upsertCheckin(_, { bookingNo = null, checkin, guest } = {}) {
    let response
    if (checkin.id) {
      response = await CheckinsRepository.patchCheckin({ checkin })
    } else {
      response = await CheckinsRepository.postCheckin({
        bookingNo,
        checkin,
        guest,
      })
    }

    if (response.status === 200) return processBooking(response.data)
  },

  async upsertMinor({ dispatch }, { bookingNo, minor } = {}) {
    let response
    if (minor.id) {
      response = await MinorsRepository.patchMinor({ bookingNo, minor })
    } else {
      response = await MinorsRepository.postMinor({
        bookingNo,
        minor,
      })
    }

    if (response.status === 200) return dispatch('fetchBooking', { bookingNo })
  },

  async deleteMinor({ dispatch }, { bookingNo, minor } = {}) {
    let response
    if (minor.id) {
      response = await MinorsRepository.deleteMinor({ bookingNo, minor })
    }

    if (response.status === 200) return dispatch('fetchBooking', { bookingNo })
  },

  async moveMinor(_, { bookingNo, minor, newBookingNo }) {
    const response = await MinorsRepository.moveMinor({
      bookingNo,
      minor,
      newBookingNo,
    })

    if (response.status === 200) return processBooking(response.data)
  },

  async connectBookings({ commit }, { bookingNo, connectingBookingNo }) {
    const response = await BookingsRepository.connectBookings({
      bookingNo,
      connectingBookingNo,
    })
    if (response.status === 200) {
      commit('SET_BOOKING', processBooking(response.data))
    }
  },

  async cancelConnectingBookings(_, { bookingNo, connectingBookingNo }) {
    await BookingsRepository.cancelConnectingBookings({
      bookingNo,
      connectingBookingNo,
    })
  },

  async syncConnectingBookings({ commit }, { bookingNo, previousBookingNo }) {
    const response = await BookingsRepository.syncConnectingBookings({
      bookingNo,
      previousBookingNo,
    })
    if (response.status === 200) {
      commit('SET_BOOKING', processBooking(response.data))
    }
  },

  async updateCheckin(_, { checkin }) {
    const response = await CheckinsRepository.patchCheckin({ checkin })
    if (response.status === 200) return response.data
  },

  async deleteCheckin(_, { checkin }) {
    const response = await CheckinsRepository.deleteCheckin({ checkin })
    if (response.status === 200) return processBooking(response.data)
  },

  async approveCheckin(_, { checkin }) {
    const response = await CheckinsRepository.approveCheckin({ checkin })
    if (response.status === 200) return processBooking(response.data)
  },

  async declineCheckin(_, { checkin, declineReason }) {
    const response = await CheckinsRepository.declineCheckin({
      checkin,
      declineReason,
    })
    if (response.status === 200) return processBooking(response.data)
  },

  async moveCheckin(_, { checkin, newBookingNo, overridePrimary }) {
    const response = await CheckinsRepository.moveCheckin({
      checkin,
      newBookingNo,
      overridePrimary,
    })

    if (response.status === 200) return processBooking(response.data)
  },

  async changePrimaryGuest(
    { dispatch, commit },
    { bookingNo, newPrimaryCheckinId }
  ) {
    const response = await BookingsRepository.changePrimaryGuest({
      bookingNo,
      newPrimaryCheckinId,
    })
    if (response.status === 200) {
      commit(
        'SET_BOOKING',
        await dispatch('fetchBooking', {
          bookingNo: bookingNo,
        })
      )
    }
  },

  async undoCheckout({ dispatch, commit }, { bookingNo }) {
    const response = await BookingsRepository.undoCheckout({ bookingNo })

    if (response.status === 200) {
      commit('SET_BOOKING', await dispatch('fetchBooking', { bookingNo }))
    }
  },
}
